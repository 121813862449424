<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div class="d-flex justify-center">
    <v-card class="card_profile" width="600" height="400">
      <div id="img_avatar">
        <template v-if="loadingImg">
          <v-row
            class="fill-height ma-0 placeholder"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="green lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <template v-else>
          <v-img
            class="img_profile"
            alt="avatar"
            height="250"
            width="250"
            :src="avatar"
          >
          </v-img>
        </template>
      </div>

      <!--    <div class="contacts">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              to="/contacts"
              v-on="on"
              v-bind="attrs"
              :loading="loadingImg"
              icon
              color="primary"
              ><v-icon>mdi-contacts</v-icon></v-btn
            >
          </template>
          <span>Contacts</span>
        </v-tooltip>
      </div> -->
      <div class="actions">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="edit = true"
              v-on="on"
              v-bind="attrs"
              :loading="loadingImg"
              icon
              color="primary"
              ><v-icon>mdi-pencil</v-icon></v-btn
            >
          </template>
          <span>Edit Profile</span>
        </v-tooltip>
      </div>
      <v-card-text>
        <template v-if="!edit">
          <div class="datos_profile flex-column">
            <h2>{{ profile.rol }}</h2>
            <h3>
              {{ profile.fullname }}
              <v-icon color="info" small @click="openDialogName"
                >mdi-pencil</v-icon
              >
            </h3>
            <p class="text--secondary">{{ profile.email }}</p>
          </div>
          <v-divider></v-divider>
          <div class="datos_secondarys">
            <h4>
              PIN:
              {{
                profile.pin == null || profile.pin == undefined
                  ? "----"
                  : profile.pin
              }}
            </h4>
          </div>
          <div class="datos_secondarys flex-column">
            <h4>Username: {{ profile.username }}</h4>
            <h4>Phone: {{ profile.phone }}</h4>
            <template v-if="profile.company != undefined">
              <p class="text--secondary">Company:{{ profile.company.name }}</p>
            </template>
          </div>
          <v-divider></v-divider>
          <div class="d-flex flex-column align-center justify-center mt-4">                

            <v-dialog
              v-model="dialogName"
              persistent
              :overlay="false"
              max-width="300px"
              transition="dialog-transition"
            >
              <v-card>
                <v-card-title primary-title class="primary white--text">
                  Change Fullname
                </v-card-title>
                <v-card-text class="mt-4">
                  <v-text-field
                    label="Fullname"
                    outlined
                    dense
                    v-model="newName"
                  ></v-text-field>
                  <div class="d-flex justify-center">
                    <v-btn
                      color="error"
                      :disabled="loadingDisable"
                      text
                      @click="cancelDialogName"
                      >cancel</v-btn
                    >
                    <v-btn
                      :loading="loadingDisable"
                      :disabled="loadingDisable || newName == ''"
                      color="info"
                      text
                      @click="confirmChangeName"
                      >change</v-btn
                    >
                  </div>
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
        </template>
        <template v-else>
          <div class="datos_profile_edit flex-column">
            <v-file-input
              outlined
              dense
              v-model="img"
              accept="image/*"
              label="Avatar"
            ></v-file-input>
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="loadingImg" @click="cancel" color="error"
              >Cancel</v-btn
            >
            <v-btn
              :loading="loadingImg"
              :disabled="loadingImg || img == null"
              @click="editProfile"
              color="success"
              >Edit</v-btn
            >
          </v-card-actions>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { getAPI } from "@/api/axios-base";
import { apiBaseUrl } from "@/enviorment";
import store from "@/store";
import axios, { AxiosError } from "axios";
import { notifyError, notifyInfo } from "@/components/Notification";

export default Vue.extend({
  name: "user-profile",
  data() {
    return {
      edit: false,
      img: null,
      srci: "",
      loadingImg: false,
      dialogName: false,

      newName: "",
   
      loadingDisable: false,
    };
  },

  computed: {
    ...mapState(["profile", "avatar"]),
  },
  methods: {
    ...mapMutations(["updateAvatar", "updateProfile", ]),
    ...mapActions(["getProfileData"]),
    openDialogName() {
      this.dialogName = true;
      this.newName = this.profile.fullname;
    },
    cancelDialogName() {
      this.dialogName = false;
      this.newName = "";
    },
    confirmChangeName() {
      this.loadingDisable = true;
      getAPI
        .post("/users/updatePassword", { fullname: this.newName })
        .then(() => {
          this.loadingDisable = false;
          this.cancelDialogName();
          this.getProfileData();
          notifyInfo("Your fullname have been updated successfully");
        })
        .catch(() => {
          this.loadingDisable = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    cancel() {
      this.img = null;
      this.edit = false;
    },
    async editProfile() {
      const formData = new FormData();
      formData.append("file", this.img);
      this.loadingImg = true;
      const res = (await getAPI.post("/users/uploadAvatar/", formData)).data;

      if (res != undefined) {
        const protemp = JSON.parse(localStorage.getItem("profile"));
        let data = res.avatarUrl;
        data = data.split("/");
        data = data[data.length - 1];
        protemp.avatar = data;

        this.updateProfile(protemp);
        await this.srcimg();
        this.loadingImg = false;
        this.cancel();
      }
    },

    async srcimg() {
      const token = store.state.accessToken;
      const auth =
        token != undefined && token != null && token != ""
          ? {
              Accept: "application/pdf",
              Authorization: `Bearer ${token}`,
            }
          : {
              Accept: "application/pdf",
            };
      this.loadingImg = true;
      const url = `${apiBaseUrl}/users/avatar/${this.profile.avatar}`;
      axios({
        method: "get",
        url: url,
        responseType: "blob",
        headers: auth,
      }).then((response) => {
        const buffer = new Blob([response.data]);
        const file_path = URL.createObjectURL(buffer);
        this.updateAvatar(file_path);

        this.loadingImg = false;
      });
    },

  
   

 
  },
});
</script>
<style lang="scss" scoped>
.card_profile {
  position: relative !important;
  margin-top: 125px;
}
.genpin {
  margin-left: 120px !important;
}

#img_avatar {
  position: absolute !important;
  border-radius: 50% !important;
  top: -125px !important;
  left: 30%;
  z-index: 200;
  margin-bottom: 30px;
  background: rgba($color: #969292be, $alpha: 0.1);
}
.placeholder {
  position: absolute;
  z-index: 500;
}
.img_profile {
  border-radius: 50%;
}
.actions {
  position: absolute !important;

  top: 0;
  right: 0;
}
.contacts {
  position: absolute !important;

  top: 0;
  left: 0;
}

.datos_profile {
  margin-top: 130px !important;
  display: flex;
  justify-content: center;
  text-align: center;
  justify-items: center;
}
.datos_profile_edit {
  margin-top: 130px !important;
  display: flex;
  justify-content: center;
  text-align: center;
  justify-items: center;
}
.datos_secondarys {
  display: flex;
  justify-content: center;
  text-align: center;
  justify-items: center;
}
</style>
